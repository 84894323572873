import { GridItem, SimpleGrid, Text } from "@chakra-ui/react"

export const TableCardContent = ({ label, body }) => {
  return (
    <SimpleGrid columns={{ base: 2, md: 1 }}>
      <GridItem>
        <Text
          display={{ base: 'inherit', md: 'none' }}
          as="p"
          mb="0"
          fontSize="md"
          textTransform="capitalize"
          fontWeight="bold"
        >
          {label}:
        </Text>
      </GridItem>
      <GridItem>
        {body}
      </GridItem>
    </SimpleGrid>
  )
}